const routes = {
  path: '/coachaccount',
  name: 'CoachAccountMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/Main.vue'),
  children: [
    {
      path: 'coachagree',
      name: 'CoachAgree',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachAgree.vue'),
      props: true,
      meta: {
        titleText: '회원가입'
      }
    },
    {
      path: 'coachcertify',
      name: 'CoachCertify',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachCertify.vue'),
      props: true,
      meta: {
        titleText: '본인인증'
      }
    },
    {
      path: 'coachjoin1',
      name: 'CoachJoin1',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin1.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin2',
      name: 'CoachJoin2',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin2.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin3',
      name: 'CoachJoin3',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin3.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin4',
      name: 'CoachJoin4',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin4.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin5',
      name: 'CoachJoin5',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin5.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin6',
      name: 'CoachJoin6',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin6.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin7',
      name: 'CoachJoin7',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin7.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin8',
      name: 'CoachJoin8',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin8.vue'),
      props: true,
      meta: {
        titleText: '정보입력'
      }
    },
    {
      path: 'coachjoin9',
      name: 'CoachJoin9',
      component: () => import(/* webpackChunkName: "coachaccount" */ '@/views/coachaccount/CoachJoin9.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout'
      },
    },
  ]
}

export default routes