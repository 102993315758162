import request from './axios';

const write = (category, title, content) => {
  const formData = new FormData();
  formData.append('category', category);
  formData.append('title', title);
  formData.append('content', content);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_inquiry',
    method: 'post',
    data: formData,
  });
};

const get = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_inquiry',
    method: 'post',
    data: formData,
  });
};

const getDetail = (qa_id) => {
  const formData = new FormData();
  formData.append('qa_id', qa_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_inquiry_detail',
    method: 'post',
    data: formData,
  });
};

const QnaService = {
  write,
  get,
  getDetail
};

export default QnaService;
