import axios, { AxiosError } from 'axios';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  timeout: 30000,
});

client.interceptors.request.use(
  function (config) {
    let accessToken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')
    config.headers.eotoken = 'Bearer ' + accessToken

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
  const onSuccess = (response) => response.data;

  const onError = (error) => {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response?.data);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
