import request from './axios';

const getLatest = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_latest_review',
    method: 'post',
  });
};

const get = (co_id) => {
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_review',
    method: 'post',
    data: formData,
  });
};

const getMyReview = () => {

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_my_review',
    method: 'post',
  });
};

const set = (rv_id, od_id, grade, content, files) => {
  const formData = new FormData();

  if (rv_id){
    formData.append('rv_id', rv_id);
  }

  formData.append('od_id', od_id);
  formData.append('grade', grade);
  formData.append('content', content);

  if (files){
    files.forEach(function (item, index){
      formData.append(`files[${index}]`, item);
    })
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_review',
    method: 'post',
    data: formData,
  });
};

const getDetail = (rv_id) => {
  const formData = new FormData();
  formData.append('rv_id', rv_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_review_detail',
    method: 'post',
    data: formData,
  });
};

const del = (rv_id) => {
  const formData = new FormData();
  formData.append('rv_id', rv_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_review',
    method: 'post',
    data: formData,
  });
};

const ReviewService = {
  getLatest,
  get,
  getMyReview,
  set,
  getDetail,
  del
};

export default ReviewService;
