<template>
  <button
    type="button"
    class="icon"
    aria-controls="filtering"
    @click='doFilter()'
    data-testid="filter"
  >
    <img
      src="/img/ico_filter.png"
    />
  </button>
</template>

<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {Offcanvas} from 'bootstrap'

const store = useStore()

const router = useRouter()

//로그인 여부
const isLogin = computed(() => store.getters.isLogin)

const doFilter = () => {
  if (!isLogin.value) {
    if (confirm('로그인 후 사용하실 수 있습니다.\n로그인 하시겠습니까?')){
      router.push({name: 'Login'})
    }
    return
  }

  const option = document.getElementById('filtering');
  const optionModal = new Offcanvas(option)
  optionModal.show();
}

</script>

