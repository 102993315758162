import request from './axios';

const get = () => {

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_faq',
    method: 'post',
  });
};

const FaqService = {
  get,
};

export default FaqService;
