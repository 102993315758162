import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils'


import 'bootstrap/dist/js/bootstrap.js'
import {VueCookieNext} from 'vue-cookie-next'

import { Quasar } from 'quasar'
import Inipay from '@/pay/inipay'
import Inipaymobile from '@/pay/inipaymobile'
// Import icon libraries
//import '@quasar/extras/material-icons/material-icons.css'
// Import Quasar css
//import 'quasar/dist/quasar.css'

const iniPay = new Inipay({
  mid: 'INIpayTest',
  signKey: 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS',
})
iniPay.initialize()

const inipaymobile = new Inipaymobile({
  P_MID: 'INIpayTest'
})
inipaymobile.initialize()


const app = createApp(App)
app.use(VueCookieNext)
app.config.globalProperties.$store = store
app.config.globalProperties.$iniPay = iniPay
app.config.globalProperties.$inipaymobile = inipaymobile
app.use(store)
app.use(Quasar)
app.use(router).mount('#app')