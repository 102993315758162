import store from '../store';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Number.prototype.numberFormat = function(){
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.valueOf())
}

String.prototype.numberFormat = function(){
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(+this.valueOf())
}

String.prototype.openLink = function(){
  try {
    if (store.getters.getOs === 'app'){
      window.reactNative.postMessage(JSON.stringify({method: 'orderBrowser', func: '', item: {url: this.valueOf() }}))
    } else {
      window.open(this.valueOf(), '_blank')
    }
  } catch (e) {
    console.log(e)
  }
  return null
}

String.prototype.b64toBlob = function(contentType='', sliceSize=512){
  const byteCharacters = atob(this.valueOf());
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}