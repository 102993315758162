import merge from 'lodash/merge';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { sha256 } from 'js-sha256';
import sortBy from 'lodash/sortBy';
import qs from 'qs';

export const defaultRequestDataFields = {
  P_MID: {
    name: '상점아이디',
    required: true
  },
  P_INI_PAYMENT: {
    name: '결제수단',
    required: true
  },
  P_OID: {
    name: '주문번호',
    required: true
  },
  P_AMT: {
    name: '결제금액',
    required: true
  },
  P_GOODS: {
    name: '상품명',
    required: true
  },
  P_UNAME: {
    name: '구매자명',
    required: true
  },
  P_MOBILE: {
    name: '구매자 Mobile 번호',
    required: false
  },
  P_EMAIL: {
    name: '구매자 Email',
    required: false
  },
  P_NEXT_URL: {
    name: '결과수신 URL',
    required: true
  },
};

export const payList = {
  CARD: '신용카드',
  BANK: '실시간계좌이체',
  VBANK: '가상계좌(무통장입금)',
  MOBILE: '휴대폰',
  BCSH: '도서문화상품권',
  NOAUTHCARD: '비인증결제',
};

class Inipaymobile {
  constructor(options = {}) {
    let { P_MID } = options;
    try {
      if (!P_MID) {
        throw new Error('mid를 확인해주세요.');
      }

      this.defaultRequestDataFields = defaultRequestDataFields;
      this.P_MID = P_MID;
      this.form = null;
      this.url = null;
      this.config = merge(
        {},
        options
      );
    } catch (err) {
      console.error(err);
    }
  }

  initialize(formOptions) {
    this.setInipayURL();
    this.generateForm(formOptions);
  }

  /**
   * 이니페이 URL 세팅 함수
   */
  setInipayURL() {
    this.url = 'https://mobile.inicis.com/smart/payment/'
  }

  generateForm(options) {

    const defaultOptions = {
      iframeSize: { width: '600px', height: '820px' },
      popup: true,
      id: this.getRandomStr(),
      name: this.getRandomStr(),
      method: 'post'
    };
    const mergedOptions = merge({}, defaultOptions, options);
    const { id, name, method } = mergedOptions;
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('style', 'height:0;width:0;border:0;border:none;visibility:hidden;');
    this.iframe.setAttribute('id', 'inipayframe');
    this.form = document.createElement('form');

    this.form.setAttribute('id', id);
    this.form.setAttribute('name', name);
    this.form.setAttribute('method', method);
    this.form.setAttribute('action', this.url);
    this.form.setAttribute('target', 'inipayframe');

    document.body.appendChild(this.iframe);
    document.body.appendChild(this.form);
  }

  getRandomStr() {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }

  pay(fields) {
    const {
      P_MID
    } = this;
    const { P_OID } = this._getProtectedFields(P_MID);
    const mergedFields = Object.assign({}, fields, {
      P_MID,
      P_OID
    });
    const fieldKeys = Object.keys(mergedFields);

    if (!this.validationRequiredFields(fieldKeys)) return false;

    this.attachFormBeforePay(mergedFields);
    this.requestInipay();
  }

  requestInipay() {
    this.form.submit();
  }

  attachFormBeforePay(mergedFields) {

    this.form.innerHTML = '';
    map(mergedFields, (v, k) => {
      this.appendFormInput(v, k);
    });

    // this.iframe.appendChild(this.form);
  }

  _getProtectedFields(P_MID) {
    const timestamp = this.getTimestamp();
    const P_OID = `${P_MID}_${timestamp}`

    return {
      timestamp,
      P_OID
    };
  }

  validationRequiredFields(fields) {
    let boolean = true;
    let missingFields = [];
    Object.keys(this.defaultRequestDataFields)
      .filter(k => this.defaultRequestDataFields[k].required)
      .map(field => {
        const hasField = fields.find(v => v === field);
        if (!hasField) {
          missingFields.push(field);
          boolean = false;
        }
      });

    if (!boolean && !this.config.production) {
      console.warn(`${missingFields.join(', ')} 필드가 누락되었습니다.`);
    }

    return boolean;
  }

  appendFormInput(value, key) {
    const input = document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', value);

    this.form.appendChild(input);
  }

  getTimestamp() {
    return Date.now();
  }
}

export default Inipaymobile;
