const routes = {
  path: '/members',
  name: 'MembersMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "members" */ '@/views/members/Main.vue'),
  children: [
    {
      path: 'mypage',
      name: 'MyPage',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/MyPage.vue'),
      props: true,
      meta: {
        titleText: '마이페이지',
        class: 'bg-gradient',
        back: false,
        back_w: true
      },
    },
    {
      path: 'coachmypage',
      name: 'CoachMyPage',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/CoachMyPage.vue'),
      props: true,
      meta: {
        titleText: '마이페이지',
        class: 'bg-gradient',
        back: false,
        back_w: true
      },
    },
    {
      path: 'myinfo',
      name: 'MyInfo',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/MyInfo.vue'),
      props: true,
      meta: {
        titleText: '내정보'
      },
    },
    {
      path: 'coachmyinfo',
      name: 'CoachMyInfo',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/CoachMyInfo.vue'),
      props: true,
      meta: {
        titleText: '내정보'
      },
    },
    {
      path: 'follow',
      name: 'Follow',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/Follow.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
      },
    },
    {
      path: 'setting',
      name: 'Setting',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/Setting.vue'),
      props: true
    },
    {
      path: 'paylist',
      name: 'PayList',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/PayList.vue'),
      props: true,
      meta: {
        titleText: '결제내역'
      },
    },
    {
      path: 'myreview',
      name: 'MyReview',
      component: () => import(/* webpackChunkName: "members" */ '@/views/members/MyReview.vue'),
      props: true,
      meta: {
        titleText: '최근코칭후기'
      },
    },
  ]
}

export default routes