<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="filtering"
    aria-labelledby="offcanvasBottomLabel"
    data-testid='filtering'
  >

    <div
      class="offcanvas-header"
    >

      <p
        class="t18 fw-bold"
        id="offcanvasBottomLabel"
      >
        관심 트레이닝 분야
        <span
          class="t13 base5"
        >
          (중복선택 가능)
        </span>

      </p>

      <button
        type="button"
        class="text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        ref='closeCanvas'
      />

    </div>

    <!-- 정렬 -->
    <Sort
      v-model='order'
      v-if="props.options !== 'no'"
    />

    <div
      class="border-top line8 d-flex"
    >

      <!-- 구분 -->
      <Type
        v-model='type'
      />

      <!-- 목소리 -->
      <Voice
        v-show='type === "voice"'
        v-model='voice'
      />

      <!-- 비언어 -->
      <NonLanguage
        v-show='type === "non_language"'
        v-model='nonLanguage'
      />

      <!-- 멘탈케어 -->
      <MentalCare
        v-show='type === "mental_care"'
        v-model='mentalCare'
      />

    </div>

    <div
      class="border-top line8 mt-auto"
    >

      <div
        class="d-flex align-items-center justify-content-start tag"
      >

        <div
          class="rounded-pill bg-base9 t13 fw-medium base2"
          v-for="(item, idx) in tags" :key="idx"
          :data-testid="`tag${idx + 1}`"
        >
          {{ item }}

          <button
            type="button"
            class="ic_x"
            @click='doRemove(item)'
          >
            <img
              src="/img/ic_x.png"
              class="w-100"
            />
          </button>

        </div>

      </div>

      <div
        class="d-flex align-items-center"
      >

        <button
          type="button"
          class="q-btn bg-secondary large max-60"
          @click="doReset"
          data-testid='resetBtn'
        >
          <img
            src="/img/ico_re.png"
            class="max-20"
          />
        </button>

        <Button
          :btnType="1"
          :btnText="'관심분야 등록'"
          :btnBg="'primary'"
          @click="doSave"
          data-testid='saveBtn'
        />

      </div>

    </div>

  </div>

</template>

<script setup>
import {Check, Button} from '@/components'
import {inArray} from '@/utils/filters'
import {Sort, Type, Voice, NonLanguage, MentalCare} from '@/components/coachAccount/filter'
import {onMounted, ref, defineEmits, defineExpose, computed, defineProps} from 'vue'
import {useStore} from 'vuex'

const props = defineProps(['limit', 'options'])

const emit = defineEmits(['onChange'])

const store = useStore()

//정렬
const order = ref('인기순')

//구분
const type = ref('voice')

//보이스
const voice = ref([])

//비언어
const nonLanguage = ref([])

//멘탈케어
const mentalCare = ref([])

const tags = computed(() => [
  ...voice.value?.map(value => `${value}(목소리)`) ?? [],
  ...nonLanguage.value?.map(value => `${value}(비언어)`) ?? [],
  ...mentalCare.value?.map(value => `${value}(멘탈케어)`) ?? []
])

const closeCanvas = ref(null)

onMounted(async () => {
  try {
    const user = await store.getters.user

    order.value = user?.order

    voice.value = user?.interest
      .filter(value => value.type === 'voice')
      .map(item => item.name)

    nonLanguage.value = user?.interest
      .filter(value => value.type === 'non_language')
      .map(item => item.name)

    mentalCare.value = user?.interest
      .filter(value => value.type === 'mental_care')
      .map(item => item.name)
  } catch (e) {
    console.log(e.message)
  }

})

//리셋
const doReset = async () => {

  try {
    const user = await store.getters.user

    order.value = user?.order

    voice.value = user?.interest
      .filter(value => value.type === 'voice')
      .map(item => item.name)

    nonLanguage.value = user?.interest
      .filter(value => value.type === 'non_language')
      .map(item => item.name)

    mentalCare.value = user?.interest
      .filter(value => value.type === 'mental_care')
      .map(item => item.name)
  } catch (e) {
    console.log(e.message)
  }

}

const doRemove = (item) => {
  if (item.includes('(목소리)')){
    voice.value = voice.value
      .filter(value => value !== item.replace('(목소리)', ''))
    return
  }

  if (item.includes('(비언어)')){
    nonLanguage.value = nonLanguage.value
      .filter(value => value !== item.replace('(비언어)', ''))
    return
  }

  if (item.includes('(멘탈케어)')){
    mentalCare.value = mentalCare.value
      .filter(value => value !== item.replace('(멘탈케어)', ''))
    return
  }
}

const doSave = () => {
  if (props?.limit && props?.limit < tags.value.length){
    alert(`${props?.limit}개 지정 가능합니다.`)
    return
  }

  emit(
    'onChange',
    order.value,
    voice.value,
    nonLanguage.value,
    mentalCare.value
  )

}

const doClose = () => {
  closeCanvas.value.click()
}

defineExpose({
  doClose
})

</script>
