const routes = {
  path: '/account',
  name: 'AccountMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "account" */ '@/views/account/Main.vue'),
  children: [
    {
      path: 'agree',
      name: 'Agree',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Agree.vue'),
      props: true,
      meta: {
        titleText: '약관동의'
      },
    },
    {
      path: 'terms',
      name: 'Terms',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Terms.vue'),
      props: true,
      meta: {
        titleText: '회원 가입 및 운영약관'
      },
    },
    {
      path: 'certify',
      name: 'Certify',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Certify.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '본인인증'
      },
    },
    {
      path: 'join1',
      name: 'Join1',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join1.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '정보입력'
      },
    },
    {
      path: 'join2',
      name: 'Join2',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join2.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '정보입력'
      },
    },
    {
      path: 'join3',
      name: 'Join3',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join3.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '정보입력'
      },
    },
    {
      path: 'join4',
      name: 'Join4',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join4.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '정보입력'
      },
    },
    {
      path: 'join5',
      name: 'Join5',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join5.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
        titleText: '정보입력'
      },
    },
    {
      path: 'join6',
      name: 'Join6',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Join6.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout'
      },
    },
    {
      path: 'findid',
      name: 'FindId',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/FindId.vue'),
      props: true,
      meta: {
        titleText: '아이디 찾기'
      },
    },
    {
      path: 'reset',
      name: 'Reset',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Reset.vue'),
      props: true,
      meta: {
        titleText: '비밀번호 재설정'
      },
    },
    {
      path: 'findpw',
      name: 'FindPw',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/FindPw.vue'),
      props: true,
      meta: {
        titleText: '비밀번호 찾기'
      },
    },
    {
      path: 'result',
      name: 'Result',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Result.vue'),
      props: true,
      meta: {
        titleText: '아이디 찾기'
      },
    },
    {
      path: 'restore',
      name: 'Restore',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Restore.vue'),
      props: true,
      meta: {
        titleText: '휴면 복구'
      },
    },
    {
      path: 'login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Login.vue'),
      props: true
    }
  ]
}

export default routes