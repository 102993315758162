<template>
  <div
    class="d-flex flex-column min-vh-100"
  >
    <Nav
      :navType="'sm'"
      :back="$route?.meta?.back"
      :back_w="$route?.meta?.back_w"
      :txtType="$route?.meta?.txtType"
      :titleText="$route?.meta?.titleText"
      :class='$route?.meta?.class'
    />

    <Tab
      :tabsText="['코칭상담사례', isCoach ? '코칭상담내역' : '내상담사례내역', '1:1상담신청내역']"
      :tabs="['All', 'MyCase', 'Ask']"
    />

    <slot />

  </div>
</template>

<script setup>
import {Nav, Tab} from '@/components'
import {useStore} from 'vuex'
import {computed} from 'vue'

const store = useStore()

//코치 여부
const isCoach = computed(() => store.getters.isCoach)

</script>
