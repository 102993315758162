import request from './axios';

const doLogin = (id, pw) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('pw', pw);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/login',
    method: 'post',
    data: formData,
  });
};

const doSnsLogin = (sns_id) => {
  const formData = new FormData();
  formData.append('sns_id', sns_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/sns_login',
    method: 'post',
    data: formData,
  });
};

const LoginService = {
  doLogin,
  doSnsLogin
};

export default LoginService;
