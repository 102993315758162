const genderHangul = (gender) => {
  return gender === '1' ? '남' : '여'
}

const phoneNumber = (value) => {
  if (!value) {
    return '';
  }

  value = value.replace(/[^0-9]/g, '');

  let result = [];
  let restNumber = '';

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith('02')) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith('1')) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val) => val).join('-');
}

const emailChk = (value) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
  return regex.test(value)
}

const isPassword = (value) => {
  let num = value.search(/[0-9]/g);
  let eng = value.search(/[a-z]/ig);
  let spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

  if (value.length < 8 ){
    return true;
  } else if (value.search(/\s/) != -1){
    return true;
  } else if (num < 0 || eng < 0 || spe < 0 ){
    return true;
  } else {
    return false;
  }
}

const inArray = (val, arr) => {
  for (let i=0; i < arr.length; i++) {
    if (arr[i] === val) return true;
  }

  return false;
}

const selectOs = () => {
  let userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.search('jandroid') > -1)
    return 'android'
  else if (userAgent.search('jios') > -1)
    return 'ios'
  else
    return 'web'
}


export {genderHangul, phoneNumber, emailChk, isPassword, inArray, selectOs}
