const routes = {
  path: '/board',
  name: 'BoardMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "board" */ '@/views/board/Main.vue'),
  children: [
    {
      path: 'service',
      name: 'Service',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/Service.vue'),
      props: true,
      meta: {
        titleText: '이용약관'
      },
    },
    {
      path: 'alarm',
      name: 'Alarm',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/Alarm.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout',
      },
    },
    {
      path: 'noticelist',
      name: 'NoticeList',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/NoticeList.vue'),
      props: true,
      meta: {
        titleText: '공지사항'
      },
    },
    {
      path: 'noticeview',
      name: 'NoticeView',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/NoticeView.vue'),
      props: true,
      meta: {
        titleText: '공지사항'
      },
    },
    {
      path: 'about',
      name: 'About',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/About.vue'),
      meta: {
        dep: 1,
        titleText: '고객센터',
        layout: 'CustomerCenterLayout',
      },
      props: true
    },
    {
      path: 'faq',
      name: 'Faq',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/Faq.vue'),
      meta: {
        dep: 2,
        titleText: '고객센터',
        layout: 'CustomerCenterLayout',
      },
      props: true
    },
    {
      path: 'inquirylist',
      name: 'InquiryList',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/InquiryList.vue'),
      meta: {
        dep: 3,
        titleText: '고객센터',
        layout: 'CustomerCenterLayout',
      },
      props: true
    },
    {
      path: 'inquiryview',
      name: 'InquiryView',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/InquiryView.vue'),
      meta: {
        dep: 3,
        titleText: '고객센터',
        layout: 'CustomerCenterLayout',
      },
      props: true
    },
    {
      path: 'inquirywrite',
      name: 'InquiryWrite',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/InquiryWrite.vue'),
      meta: {
        dep: 3,
        titleText: '고객센터',
        layout: 'CustomerCenterLayout',
      },
      props: true
    },
    {
      path: 'review',
      name: 'Review',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/Review.vue'),
      props: true,
      meta: {
        layout: 'NoneLayout'
      },
    },
    {
      path: 'reviewwrite',
      name: 'ReviewWrite',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/ReviewWrite.vue'),
      props: true,
      meta: {
        titleText: '후기등록'
      },
      beforeEnter: async (to, from, next) => {
        if (to?.query?.rv_id){
          to.meta.titleText = '후기수정'
        }
        next();
      },
    },
    {
      path: 'addlist',
      name: 'AddList',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/AddList.vue'),
      props: true,
      meta: {
        titleText: '제휴광고신청'
      },
    },
    {
      path: 'addview',
      name: 'AddView',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/AddView.vue'),
      props: true,
      meta: {
        titleText: '제휴광고신청'
      },
    },
    {
      path: 'addwrite',
      name: 'AddWrite',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/AddWrite.vue'),
      props: true,
      meta: {
        titleText: '제휴광고신청'
      },
    },
    {
      path: 'privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "board" */ '@/views/board/Privacy.vue'),
      props: true,
      meta: {
        titleText: '개인정보처리방침'
      },
    },
  ]
}

export default routes