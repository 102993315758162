<template>
  <div
    class="icon position-relative"
    @click='doAlarm'
    data-testid='alarmBtn'
  >
    <img
      src="/img/ico_alarm.png"
    />

    <span
      class="new position-absolute end-0 top-0"
      v-if="isNew"
      data-testid='isNew'
    />
  </div>
</template>

<script setup>
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {computed, ref, onBeforeMount} from 'vue'
import AlarmService from '@/api/AlarmService'

const store = useStore()

const router = useRouter()

//로그인 여부
const isLogin = computed(() => store.getters.isLogin)

//읽지 않은 알림 여부
const isNew = ref(false)

onBeforeMount(async () => {
  try {
    const response = await AlarmService.getNew()
    isNew.value = response?.is_new
  } catch (e) {
    console.log(e?.message)
  }
})

const doAlarm = () => {
  if (!isLogin.value){
    if (confirm('로그인 후 사용하실 수 있습니다.\n로그인 하시겠습니까?')){
      router.push({name: 'Login'})
    }
    return
  }

  router.push({name: 'Alarm'})
}
</script>
