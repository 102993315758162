<template>
  <div
    class="chkBox"
  >
    <Check
      :chkType="3"
      :labelText="'전체'"
      :chkId="'t21'"
      :value="'전체'"
      v-model='isAllCheck'
    />

    <Check
      :chkType="3"
      :labelText="'면접'"
      :chkId="'t22'"
      :value="'면접'"
      v-model='nonLanguage'
    />

    <Check
      :chkType="3"
      :labelText="'발표'"
      :chkId="'t23'"
      :value="'발표'"
      v-model='nonLanguage'
    />

    <Check
      :chkType="3"
      :labelText="'CEO'"
      :chkId="'t24'"
      :value="'CEO'"
      v-model='nonLanguage'
    />

    <Check
      :chkType="3"
      :labelText="'성우'"
      :chkId="'t25'"
      :value="'성우'"
      v-model='nonLanguage'
    />

  </div>
</template>

<script setup>
import Check from '@/components/Check.vue'
import {useStore} from 'vuex'
import {computed, defineEmits, defineProps, onMounted, ref, watch} from 'vue'

const props = defineProps({
  modelValue: {
    type: [String]
  }
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()

const isAllCheck = ref('')

const nonLanguage = ref([])

const isAll = computed(() => nonLanguage.value?.length === 4)

watch(props, (newValue) => {
  nonLanguage.value = newValue.modelValue
})

watch(nonLanguage, (newValue) => {
  if (isAll.value){
    isAllCheck.value = '전체'
  } else {
    isAllCheck.value = ''
  }

  emit('update:modelValue', newValue)
})

watch(isAllCheck, (newValue) => {
  if (newValue === '전체'){
    nonLanguage.value = [
      '면접', '발표', 'CEO',
      '성우'
    ]
  } else {
    if (isAll.value){
      nonLanguage.value = []
    }
  }
})

</script>
