<template>
  <button type="button" class="snsBtn" :class="btnClass">{{ btnText }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    btnBg: {
      type : Number
    },
    btnText: {
      type: String
    },
    btnSize: {
      type: String
    }
  },
  data() {
    return {
      btnClass: [],
    }
  },
  mounted() {
    // BACKGROUND COLOR
    if ( this.btnBg === 1 ) {
      this.btnClass = ['apple-black']
    }
    if ( this.btnBg === 2 ) {
      this.btnClass = ['apple-white']
    }
    if ( this.btnBg === 3 ) {
      this.btnClass = ['apple-border']
    }
    if ( this.btnBg === 4 ) {
      this.btnClass = ['kakao']
    }
    if ( this.btnBg === 5 ) {
      this.btnClass = ['naver']
    }
    if ( this.btnBg === 6 ) {
      this.btnClass = ['facebook']
    }
    // SIZE
    if ( this.btnSize === 'wide' ) {
      this.btnClass.push('wide')
    }
    if ( this.btnSize === 'circle' ) {
      this.btnClass.push('rounded-circle', 'justify-content-center', 'p-0')
    }
  }
}
</script>
<style>
.snsBtn {display: flex; justify-content: space-between; align-items: center; width:100%; padding: 0 30px; font-size:16px; font-weight: 500; height: 54px; border-radius: 4px;}
.snsBtn::before {content: ''; display: block; width: 24px; height:40px; background-repeat: no-repeat; background-position: center; background-size: 24px;}
.snsBtn::after {content: ''; display: block; width: 24px;}
.snsBtn.apple-black {background-color: #000000;}
.snsBtn.apple-white {background-color: #FFFFFF;}
.snsBtn.apple-border {border:1px solid #000; background-color: #FFFFFF;}
.snsBtn.kakao {background-color: #FEE500;}
.snsBtn.naver {background-color: #03C75A;}
.snsBtn.facebook {background-color: #1877F2;}
.snsBtn.google {background-color: #E74133;}
.snsBtn.apple-black::before {background-image: url('/img/sns_apple_white.png');}
.snsBtn.apple-white::before,
.snsBtn.apple-border::before {background-image: url('/img/sns_apple_black.png');}
.snsBtn.kakao::before {background-image: url('/img/sns_kakao.png');}
.snsBtn.naver::before {background-image: url('/img/sns_naver_white.png');}
.snsBtn.facebook::before {background-image: url('/img/sns_facebook_white.png');}
.snsBtn.apple-black, .snsBtn.naver, .snsBtn.facebook {color:#FFF;}
.snsBtn.rounded-circle {width:44px; height: 44px;}
.snsBtn.rounded-circle::before {background-size: 18px;}
.snsBtn.rounded-circle::after {display: none;}
</style>
