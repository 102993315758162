//가이드 뷰 표시
export const setBlank = 'setBlank'

export const mainModule = {
  state: () => ({
    isBlank: false
  }),
  mutations: {
    setBlank: (state, _isBlank) => {
      state.isBlank = _isBlank
    }
  },
  getters: {
    isBlank (state) {
      return state.isBlank
    }
  }
}