const routes = {
  path: '/payment',
  name: 'PaymentMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
  },
  props: true,
  component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Main.vue'),
  children: [
    {
      path: 'cart',
      name: 'Cart',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Cart.vue'),
      props: true,
      meta: {
        titleText: '장바구니'
      }
    },
    {
      path: 'order',
      name: 'Order',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Order.vue'),
      props: true,
      meta: {
        titleText: '코칭 신청'
      }
    },
    {
      path: 'pay',
      name: 'Pay',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Pay.vue'),
      props: true,
      meta: {
        titleText: '결제 정보'
      }
    },
    {
      path: 'paydetail',
      name: 'PayDetail',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/PayDetail.vue'),
      props: true,
      meta: {
        titleText: '결제상세내역'
      },
      beforeEnter: async (to, from, next) => {
        to.meta.titleText = `결제상세내역 (${to?.query?.od_id})`
        next();
      },
    },
    {
      path: 'exact',
      name: 'Exact',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Exact.vue'),
      props: true,
      meta: {
        titleText: '정산관리'
      }
    },
    {
      path: 'bank',
      name: 'Bank',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Bank.vue'),
      props: true,
      meta: {
        layout: 'PopupLayout'
      }
    },
    {
      path: 'close',
      name: 'Close',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Close.vue'),
      props: true,
      meta: {
        layout: 'PopupLayout'
      }
    },
    {
      path: 'popup',
      name: 'Popup',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Popup.vue'),
      props: true,
      meta: {
        layout: 'PopupLayout'
      }
    },
    {
      path: 'return',
      name: 'Return',
      component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Return.vue'),
      props: true,
      meta: {
        layout: 'PopupLayout'
      }
    },
  ]
}

export default routes