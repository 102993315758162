import {createRouter, createWebHistory} from 'vue-router'

import accountPath from './path.account'
import coachaccountPath from './path.coachaccount'
import casePath from './path.case'
import membersPath from './path.members'
import boardPath from './path.board'
import coachPath from './path.coach'
import paymentPath from './path.payment'
import {MemberService} from '@/api'

const routes = [
  {
    path: '/',
    alias: '',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
    meta: {
      layout: 'MainLayout',
      logo: true,
      filter: true,
      cart: true,
      alarm: true
    },
    props: true,
    beforeEnter: async (to, from, next) => {

      let eotoken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')
      let eolevel = sessionStorage.getItem('eolevel') || localStorage.getItem('eolevel')

      if (eotoken){
        //*로그인 시 푸시 키값 제공 (메인 페이지 올때마다 키값 확인)
        window?.reactNative?.postMessage(JSON.stringify({method: 'getToken', func: 'eoToken', item: {}}))
        window.eoToken = (e) => {
          MemberService.setPushToken(e.token)
        }
      }

      if (eotoken && eolevel && eolevel > 2) {
        next({ path: '/home' });
        return;
      }

      next();
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      layout: 'MainLayout',
      logo: true,
      alarm: true
    },
    props: true,
    beforeEnter: async (to, from, next) => {

      let eotoken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')
      let eolevel = sessionStorage.getItem('eolevel') || localStorage.getItem('eolevel')

      if (!eotoken || !eolevel || eolevel < 2) {
        next({ path: '/' });
        return;
      }

      next();
    },
  },
  {
    path: '/splash',
    name: 'Splash',
    component: () => import(/* webpackChunkName: "splash" */ '@/views/Splash.vue'),
    meta: {
      layout: 'Nonelayout',
    },
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.addRoute(accountPath)
router.addRoute(coachaccountPath)
router.addRoute(casePath)
router.addRoute(membersPath)
router.addRoute(boardPath)
router.addRoute(coachPath)
router.addRoute(paymentPath)


export default router