import request from './axios';

const set = (order, voice, non_language, mental_care) => {
  const formData = new FormData();
  formData.append('order', order);

  voice.forEach((value, index) => {
    formData.append(`voice[${index}]`, value);
  })

  non_language.forEach((value, index) => {
    formData.append(`non_language[${index}]`, value);
  })

  mental_care.forEach((value, index) => {
    formData.append(`mental_care[${index}]`, value);
  })

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_interest',
    method: 'post',
    data: formData,
  });
};

const InterestService = {
  set,
};

export default InterestService;
