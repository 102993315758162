<template>
  <div
    class="icon"
    @click='doCart'
    data-testid='cartBtn'
  >
    <img
      src="/img/ico_cart.png"
    />
  </div>
</template>

<script setup>

import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {computed} from 'vue'

const store = useStore()

const router = useRouter()

//로그인 여부
const isLogin = computed(() => store.getters.isLogin)

const doCart = () => {
  if (!isLogin.value){
    if (confirm('로그인 후 사용하실 수 있습니다.\n로그인 하시겠습니까?')){
      router.push({name: 'Login'})
    }

    return
  }

  router.push({name: 'Cart'})
}
</script>

