import request from './axios';

const get = (hp) => {
  const formData = new FormData();
  formData.append('hp', hp);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/find_id',
    method: 'post',
    data: formData,
  });
};

const FindIdService = {
  get,
};

export default FindIdService;
