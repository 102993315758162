import request from './axios';

const set = (ad_id, category, subject, content) => {
  const formData = new FormData();

  if (ad_id){
    formData.append('ad_id', ad_id);
  }

  formData.append('category', category);
  formData.append('subject', subject);
  formData.append('content', content);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_ad',
    method: 'post',
    data: formData,
  });
};

const get = (last) => {
  const formData = new FormData();

  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_ad',
    method: 'post',
    data: formData,
  });
};

const getDetail = (ad_id) => {
  const formData = new FormData();
  formData.append('ad_id', ad_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_ad_detail',
    method: 'post',
    data: formData,
  });
};

const del = (ad_id) => {
  const formData = new FormData();
  formData.append('ad_id', ad_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/del_ad',
    method: 'post',
    data: formData,
  });
};


const AdService = {
  set,
  get,
  getDetail,
  del
};

export default AdService;
