<template>
  <textarea
    name=""
    id=""
    cols="30"
    rows="10"
    class="form-control"
    :class="sizeClass"
    :placeholder="props?.place"
    :disabled="props?.disabled"
    :readOnly="props?.readOnly"
    :maxlength="props?.length"
    :value="props?.modelValue"
    @input="updateInput"
    :data-testid="props?.dataTestid"
  />
</template>

<script setup>
import {defineProps, onMounted, ref, defineEmits} from 'vue'

const props = defineProps([
  'modelValue',
  'sizeType',
  'place',
  'length',
  'disabled',
  'readOnly',
  'value',
  'dataTestid'
])

const emit = defineEmits(['changeInput', 'update:modelValue'])

const sizeClass = ref([])

onMounted(() => {
  switch (+props.sizeType){
    case 1:
      sizeClass.value = ['large']
      break
    case 2:
      sizeClass.value = ['medium']
      break
    case 3:
      sizeClass.value = ['small']
      break
  }
})

const updateInput = (e) => {
  emit('changeInput', e.target.value)
  emit('update:modelValue', e.target.value)
}
</script>

<style>
textarea.form-control {
  resize: none;
  display: block;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 10px 14px;
}
textarea.form-control.large {
  height: 244px;
}
textarea.form-control.medium {
  height: 108px;
  font-size: 14px;
  line-height: 20px;
}
textarea.form-control.small {
  height: 90px;
  font-size: 14px;
  line-height: 20px;
}
textarea.form-control::placeholder {
  color: #b2b2b2;
}
textarea.form-control:focus {
  box-shadow: unset;
  border-color: #00a4fa;
  caret-color: #00a4fa;
}
textarea.form-control:disabled,
textarea.form-control[readonly] {
  background: #FFF;
  border-color: #E0E0E0;
  color: #666666;
  font-size: 11px;
  line-height: 16px;
  font-weight: 300;
  max-height: 120px;
}
</style>
