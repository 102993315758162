import request from './axios';

const sendCode = (hp, id) => {
  const formData = new FormData();
  formData.append('hp', hp);

  if (id){
    formData.append('id', id);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/send_hp_validation_code',
    method: 'post',
    data: formData,
  });
};

const ValidationService = {
  sendCode,
};

export default ValidationService;
