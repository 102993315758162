<template>
  <q-btn :color="btnBg" :label="btnText" :class="btnClass" :outline="btnOutline" />
</template>

<script>
export default {
  name: 'Buttoncopy',
  props: {
    btnType: {
      type: Number
    },
    btnText: {
      type: String
    },
    btnSize: {
      type: String
    },
    btnOutline: {
      type: Boolean
    },
    btnBg: {
      type: String
    }
  },
  data() {
    return {
      btnClass: []
    }
  },
  mounted() {
    // TYPE
    if (this.btnType === 1) {
      this.btnClass = ['large']
    }
    if (this.btnType === 2) {
      this.btnClass = ['medium']
    }
    if (this.btnType === 3) {
      this.btnClass = ['small']
    }
    if (this.btnType === 4) {
      this.btnClass = ['xsmall']
    }
  }
}
</script>

<style>
.q-btn.bg-primary,
.q-btn.bg-primary * {color: #fff !important;font-weight: 400 !important;}
.q-btn.q-btn--outline,
.q-btn.q-btn--outline * {color: #06b4ea !important;font-weight: 500 !important;}
.q-btn--outline:before {border-color: #06b4ea !important;}
.q-btn.bg-secondary,
.q-btn.bg-secondary * {color: #8c8c8c !important;font-weight: 400 !important;}
.q-btn.bg-sub,
.q-btn.bg-sub * {color: #005FCA !important;}

.q-btn {width: 100%;display: flex;align-items: center;justify-content: center;padding:0;}
.q-btn:before {box-shadow: unset;}
.q-btn.large {width: 100%;height: 54px;border-radius: 0;}
.q-btn.medium {width: 100%;height: 52px;border-radius: 4px;}
.q-btn.small {width: 100%;height: 42px;border-radius: 4px;}
.q-btn.small {height: 30px;border-radius: 4px;}
.q-btn.large,
.q-btn.large * {font-size: 18px;line-height: 26px;}
.q-btn.medium,
.q-btn.medium *,
.q-btn.small,
.q-btn.small * {font-size: 16px;line-height: 24px;}
.q-btn.xsmall,
.q-btn.xsmall * {font-size: 12px;line-height: 16px; width:unset !important; padding: 0 10px;}
.q-btn:disabled{
  background-color: #bcbcbc !important;
}
.q-btn:disabled.q-btn--outline,
.q-btn:disabled.q-btn--outline * {color: #fff !important;font-weight: 500 !important;}
.q-btn:disabled.q-btn--outline:before {border-color: #fff !important;}
</style>
