import request from './axios';

const get = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_notice_list',
    method: 'post',
    data: formData,
  });
};

const getDetail = (id) => {
  const formData = new FormData();
  formData.append('id', id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_notice',
    method: 'post',
    data: formData,
  });
};

const NoticeService = {
  get,
  getDetail
};

export default NoticeService;
