<template>

  <nav
    class="d-flex align-items-center justify-content-between bg-white position-sticky top-0 start-0"
    :class="navClass"
  >

    <div
      class="d-flex align-items-center justify-content-start"
    >

      <a
        href="/"
        class="logo"
        v-if="logo"
      >
        <img
          src="/img/logo.png"
          class="w-100"
        />
      </a>

      <div
        @click="_doBack"
        class="icon"
        v-if="back"
        data-testid="backBtn"
      >
        <img
          src="/img/ico_back.png"
        />
      </div>

      <div
        @click="_doBack"
        class="icon"
        v-if="back_w"
      >
        <img
          src="/img/ico_back_w.png"
        />
      </div>

      <p
        :class="txtClass"
        :style='back_w ? "color:#fff" : ""'
      >
        {{ titleText }}
      </p>

    </div>

    <div
      class="d-flex align-items-center justify-content-end"
    >

      <!-- 관심 트레이닝 분야 선택 버튼 -->
      <FilterButton
        v-if="filter"
      />

      <!-- 장바구니 버튼 -->
      <CartButton
        v-if="cart"
      />

      <!-- 알림함 버튼 -->
      <AlarmButton
        v-if="alarm"
      />

      <a
        onclick="history.back();"
        class="icon ms-auto"
        v-if="close"
      >
        <img
          src="/img/ico_close.png"
        />
      </a>

      <div
        class="icon ms-auto"
        v-if="bin"
        @click='emit("doDelete")'
        data-testid="deleteBtn"
      >
        <img
          src="/img/ico_bin.png"
        />
      </div>


    </div>
  </nav>
</template>

<script setup>
import {FilterButton, CartButton, AlarmButton} from '@/components/nav/index'
import {computed, defineProps, reactive, ref, onMounted, defineEmits} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

const store = useStore()

const router = useRouter()

const props = defineProps([
  'navType',
  'txtType',
  'logo',
  'back',
  'back_w',
  'searchBox',
  'txt',
  'titleText',
  'filter',
  'cart',
  'alarm',
  'close',
  'bin',
  'doBack'
])

const emit = defineEmits(['doDelete'])

const navClass = reactive([])

const txtClass = reactive([])

//로그인 여부
const isLogin = computed(() => store.getters.isLogin)

onMounted(() => {
  if (props.navType === 'lg') {
    Object.assign(navClass, ['lg'])
  }
  if (props.navType === 'md') {
    Object.assign(navClass, ['md'])
  }
  if (props.navType === 'sm') {
    Object.assign(navClass, ['sm'])
  }
  if (props.txtType === 'intro') {
    Object.assign(txtClass, ['t14', 'position-absolute', 'start-50'])
  }
  if (props.txtType === 'center') {
    Object.assign(txtClass, ['t16', 'position-absolute', 'start-50', 'fw-medium'])
  }
  if (props.txtType === 'left') {
    Object.assign(txtClass, ['t16', 'fw-medium'])
  }
})

const _doBack = () => {
  if (props.doBack){
    props.doBack()
    return
  }
  router.go(-1)
}

</script>

<style>
nav {padding: 0 20px;z-index: 10;}
nav .logo {max-width: 120px;}
nav .icon .new {width: 5px;height: 5px;border-radius: 50%;background-color: #f1294f; position: absolute;}
nav .icon img {width: 26px;height: 26px;}
nav .start-50 {transform: translateX(-50%);}

nav.lg {height: 54px;}
nav.md {height: 52px;}
nav.md .d-flex {height: 36px;padding: 0 12px;border-radius: 8px;}
nav.md .icon {margin-right: 2px;}
nav.sm {height: 44px;}
nav.sm .xsmall {border-radius: 10px;}

nav .justify-content-start .icon {margin-right: 8px;}
nav .justify-content-end .icon {margin-left: 16px; position: relative;}
nav .status {background-color: #666666;border-radius: 10px;margin-left: auto;margin-right: 8px;padding: 4.5px 6px;}
nav .form-control {border: 0;padding: 0;background: unset;font-size: 16px;line-height: 24px;color: #000000;border-radius: 0;}
nav .form-control::placeholder {color: #b2b2b2;}
nav .form-control:focus {color: #000;border: 0;outline: 0;box-shadow: unset;background: transparent;}
nav .form-control:disabled,
nav .form-control[readonly],
nav .form-control:disabled::placeholder {background: unset;color: #e0e0e0;}
</style>
