import request from './axios';

const getNew = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_alarm_read',
    method: 'post',
  });
};

const get = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_alarm_list',
    method: 'post',
    data: formData,
  });
};

const del = (id) => {
  const formData = new FormData();
  formData.append('id', id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_alarm',
    method: 'post',
    data: formData,
  });
};

const read = () => {

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/alarm_read',
    method: 'post',
  });
};

const AlarmService = {
  getNew,
  get,
  del,
  read
};

export default AlarmService;
