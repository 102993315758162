import request from './axios';

const doReset = (id, pw, type) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('pw', pw);
  formData.append('type', type);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/pw_reset',
    method: 'post',
    data: formData,
  });
};

const PwResetService = {
  doReset,
};

export default PwResetService;
