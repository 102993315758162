<template>
  <div
    class="chkBox"
  >

    <Check
      :chkType="3"
      :labelText="'전체'"
      :chkId="'t11'"
      :value="'전체'"
      v-model='isAllCheck'
      data-testid='allBtn'
    />

    <Check
      :chkType="3"
      :labelText="'발성'"
      :chkId="'t12'"
      :value="'발성'"
      v-model='voice'
      data-testid="발성"
    />

    <Check
      :chkType="3"
      :labelText="'발음'"
      :chkId="'t13'"
      :value="'발음'"
      v-model='voice'
      data-testid="발음"
    />

    <Check
      :chkType="3"
      :labelText="'면접'"
      :chkId="'t14'"
      :value="'면접'"
      v-model='voice'
      data-testid="면접"
    />

    <Check
      :chkType="3"
      :labelText="'피칭(발표)'"
      :chkId="'t15'"
      :value="'피칭(발표)'"
      v-model='voice'
      data-testid="피칭(발표)"
    />

    <Check
      :chkType="3"
      :labelText="'성우'"
      :chkId="'t16'"
      :value="'성우'"
      v-model='voice'
      data-testid="성우"
    />

    <Check
      :chkType="3"
      :labelText="'유튜브크리에이터화술'"
      :chkId="'t17'"
      :value="'유튜브크리에이터화술'"
      v-model='voice'
      data-testid="유튜브크리에이터화술"
    />

    <Check
      :chkType="3"
      :labelText="'라이브커머스'"
      :chkId="'t18'"
      :value="'라이브커머스'"
      v-model='voice'
      data-testid="라이브커머스"
    />

    <Check
      :chkType="3"
      :labelText="'힐링보이스'"
      :chkId="'t19'"
      :value="'힐링보이스'"
      v-model='voice'
      data-testid="힐링보이스"
    />

    <Check
      :chkType="3"
      :labelText="'CEO스피치'"
      :chkId="'t110'"
      :value="'CEO스피치'"
      v-model='voice'
      data-testid="CEO스피치"
    />

    <Check
      :chkType="3"
      :labelText="'낭독'"
      :chkId="'t111'"
      :value="'낭독'"
      v-model='voice'
      data-testid="낭독"
    />

    <Check
      :chkType="3"
      :labelText="'콜포비아'"
      :chkId="'t112'"
      :value="'콜포비아'"
      v-model='voice'
      data-testid="콜포비아"
    />

  </div>
</template>

<script setup>
import Check from '@/components/Check.vue'
import {useStore} from 'vuex'
import {computed, onMounted, watch, ref, defineProps, defineEmits, toRefs} from 'vue'

const props = defineProps({
  modelValue: {
    type: [String]
  }
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()

const isAllCheck = ref('')

const voice = ref([])

const isAll = computed(() => voice.value?.length === 11)

watch(props, (newValue) => {
  voice.value = newValue.modelValue
})

watch(voice, (newValue) => {
  if (isAll.value){
    isAllCheck.value = '전체'
  } else {
    isAllCheck.value = ''
  }

  emit('update:modelValue', newValue)
})

watch(isAllCheck, (newValue) => {
  if (newValue === '전체'){
    voice.value = [
      '발성', '발음', '면접',
      '피칭(발표)', '성우', '유튜브크리에이터화술',
      '라이브커머스', '힐링보이스', 'CEO스피치',
      '낭독', '콜포비아'
    ]
  } else {
    if (isAll.value){
      voice.value = []
    }
  }
})

</script>
