<template>
  <div
    class="Tab d-flex align-items-center justify-content-center text-center position-sticky start-0 bg-white"
  >
    <div
      class="t14 fw-medium w-100"
      :class="{on: +page === (idx + 1)}"
      v-for="(tab, idx) in tabs"
      :key="idx"
      :data-testid="tab"
      @click='this.$router.replace({name: tab})'
    >
      {{ tabsText[idx] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    tabsText: {
      type: Array
    },
    tabs: {
      type: Array
    }
  },
  data() {
    return {
      page: this.$route.query.tab ?? this.$route.meta.dep
    }
  },
  watch: {
    '$route.name': function () {
      this.page = this.$route.query.tab ?? this.$route.meta.dep
    }
  }
}
</script>

<style>
.Tab {top: 44px; z-index: 10;}
.Tab .t14 {color:#B2B2B2; padding-top:12px; border-bottom:1px solid #E0E0E0; display: flex; align-items: flex-start; justify-content: center; height: 50px;}
.Tab .t14.on,
.Tab .t14.router-link-exact-active,
.Tab .t14.router-link-active {color:#000; border-bottom:2px solid #06B4EA;}
</style>
