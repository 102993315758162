<template>
  <div class="form-switch">
    <input
      type="checkbox"
      :id="switchId"
      :value="value"
      :checked='isChecked'
      v-model="model"
      @input="isChecked"
    />
    <label :for="switchId" class="rounded-pill bg-base9"></label>
  </div>
</template>

<script>
export default {
  name: 'Switch',
  props: {
    modelValue: {
      type: Boolean
    },
    switchId: {
      type: String
    },
    value: {
      type: String
    },
    checked: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      check: false
    }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.value
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue]
          if (value) {
            newValue.push(this.value)
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }
          this.$emit('update:modelValue', newValue)
        } else {
          this.$emit('update:modelValue', value ? this.value : '')
        }
      },
    },
  },
  methods: {
    isChecked(e) {
      this.check = e.target.checked
      this.$emit('chkChange', this.check, e.target.value)
    }
  }
}
</script>
<style>
.form-switch {padding: 0; margin: 0;}
.form-switch input {position: absolute; appearance: none; -webkit-appearance: none; -moz-appearance: none;}
.form-switch label {position: relative; cursor: pointer; display: inline-block; width: 34px; height: 18px; transition: 0.2s;}
.form-switch label::before {content: ''; position: absolute; top: 2px; left: 2px; display: inline-block; width: 14px; height: 14px; border-radius: 50%; background: #FFFFFF; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18); transition: 0.2s;}
.form-switch input:checked + label {background: #06B4EA !important;}
.form-switch input:checked + label::before {left: 18px; box-shadow: unset;}
</style>
