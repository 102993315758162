Gnb
<template>
  <div class="Sect5 bg-sub">
    <div class="container">
      <div class="d-flex align-items-center justify-content-start mb-2">
        <div class="t12 fw-medium base4" @click="doService" data-testid="serviceBtn">이용약관</div>

        <span />

        <div class="t12 fw-medium base4" @click="doPrivacy" data-testid="privacyBtn">개인정보처리방침</div>
      </div>

      <p class="t11 base5" data-testid="company">
        주식회사 블루트리엔터 | 대표 : 이동하
        <br />
        주소 : 서울특별시 동작구 남부순환로 2053. 4층
        <br />
        사업자등록번호 : 141-81-49114 | e-mail : and0445@naver.com
        <br />
        전화번호 : 02-3444-3879
        <br />
        통신판매업신고번호 : 제 2023-서울동작-1231 호
        <br />
        ©주식회사 블루트리엔터 All rights reserved
        <br />
        <br />
        모든 거래에 대한 책임과 배송, 취소, 환불 민원등의 처리는 (주)블루트리엔터에서 진행합니다.
        <br />
        자세한 문의는 Email: (and0445@naver.com), 유선: (02-3444-3879)으로 가능합니다.
      </p>

      <div style="height: 60px" v-if="!isCoach" />
    </div>
  </div>
</template>

<script setup>
import {useRouter} from 'vue-router'
import {computed} from 'vue'
import {useStore} from 'vuex'

const store = useStore()

const router = useRouter()

//코치 여부
const isCoach = computed(() => store.getters.isCoach)

const doService = () => {
  if (isCoach.value) {
    router.push({name: 'Service', query: {co_id: 'c_provision'}})
  } else {
    router.push({name: 'Service', query: {co_id: 'n_provision'}})
  }
}

const doPrivacy = () => {
  if (isCoach.value) {
    router.push({name: 'Privacy', query: {co_id: 'c_privacy'}})
  } else {
    router.push({name: 'Privacy', query: {co_id: 'n_privacy'}})
  }
}
</script>
