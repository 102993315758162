import request from './axios';

const getPopular = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_popular_video',
    method: 'post',
  });
};

const get = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_video',
    method: 'post',
    data: formData,
  });
};

const VideoService = {
  getPopular,
  get
};

export default VideoService;
