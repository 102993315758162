<template>
  <component :is="layout ? layout : 'NoneLayout'">
    <router-view />
  </component>
</template>

<script>
import NoneLayout from './NoneLayout'
import MainLayout from './MainLayout'
import SubLayout from './SubLayout'
import GnbLayout from './GnbLayout'
import CoachingLayout from './CoachingLayout'
import PopupLayout from './PopupLayout'
import CustomerCenterLayout from './CustomerCenterLayout'

export default {
  name: 'BaseLayout',
  components: {
    NoneLayout,
    MainLayout,
    SubLayout,
    GnbLayout,
    CoachingLayout,
    PopupLayout,
    CustomerCenterLayout
  },
  data() {
    return {}
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'NoneLayout'
    }
  }
}
</script>
