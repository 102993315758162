<template>
  <div
    class="d-flex flex-column position-relative formText"
    :class="formClass"
  >

    <label
      class="form-label d-block t13 mb-0"
    >
      {{ labelText }}
    </label>

    <div
      class="d-flex align-items-center position-relative"
    >

      <button
        type="button"
        class="icon"
        v-if="searching"
        @click="searchBtnClick"
      >

        <img
          src="/img/ico_search.png"
        />

      </button>

      <input
        class="form-control"
        :type="inputType == null ? 'text' : inputType"
        :min="min"
        :max="max"
        :placeholder="place"
        :data-placeholder="dateplace"
        :required="required"
        :value="modelValue"
        ref="input"
        @input="updateInput"
        :disabled="disabled"
        :readOnly="readOnly"
        :maxlength="length"
        :data-testid="dataTestid"
        v-on:keyup.enter="searchBtnClick"
      />

      <div
        class="d-flex align-items-center position-absolute end-0"
      >

        <div
          v-if='!disabled'
        >

          <button
            type="button"
            class="icon"
            v-if="clearBtn"
            @click="clearSearch"
          >

            <img
              src="/img/ico_del.png"
              class="ms-auto"
            />

          </button>

        </div>

        <button
          type="button"
          class="icon"
          v-if="searchBtn"
          @click="searchBtnClick"
          data-testid="searchBtn"
        >

          <img
            src="/img/ico_search.png"
          />

        </button>

        <button
          type="button"
          class="icon"
          v-if="viewBtn"
        >

          <img
            src="/img/form_visible.png"
          />

          <img
            src="/img/form_invisible.png"
          />

        </button>

        <button
          type="button"
          class="okBtn"
          v-if="okBtns"
          @click="okBtnClick"
          data-testid="okBtn"
        >
          중복확인
        </button>

        <router-link
          :to="{name:'Bank'}"
          class="okBtn"
          v-if="editBtns"
          data-testid="editBtn"
        >
          수정
        </router-link>

      </div>

    </div>

    <p
      class="form_error"
      v-if="vaildation"
    >
      {{ validMessage }}
    </p>

  </div>
</template>

<script>
import {phoneNumber} from '@/utils/filters'

export default {
  name: 'FormText',
  props: [
    'modelValue',
    'place',
    'dateplace',
    'required',
    'value',
    'length',
    'inputType',
    'input',
    'inputEvent',
    'vaildation',
    'validMessage',
    'disabled',
    'readOnly',
    'formType',
    'labelText',
    'searching',
    'searchBtn',
    'viewBtn',
    'okBtns',
    'editBtns',
    'checking',
    'dataTestid',
    'min',
    'max'
  ],
  data() {
    return {
      formClass: [],
      clearBtn: false,
      search: this.modelValue
    }
  },
  mounted() {
    if (this.formType === 1) {
      this.formClass = ['round']
    }
    if (this.formType === 2) {
      this.formClass = ['line']
    }
    if (this.formType === 3) {
      this.formClass = ['round', 'inlabel']
    }
    if (this.formType === 4) {
      this.formClass = ['rounded-1', 'bg-base9']
    }
    //this.search = this.value
  },
  methods: {
    phoneNumber,
    updateInput(e) {
      this.search = e.target.value

      if (this.checking === 'number')
        this.search = this.search.replace(/[^0-9]/g, '');
      else if (this.checking === 'phone')
        this.search = this.phoneNumber(this.search.replace(/[^0-9]/g, ''));

      if (this.search.length > 0)
        this.clearBtn = true
      else
        this.clearBtn = false

      this.$emit('changeInput', this.search)
      this.$emit('update:modelValue', this.search)
    },
    clearSearch() {
      this.search = ''
      this.clearBtn = false
      this.$emit('changeInput', this.search)
      this.$emit('update:modelValue', this.search)
    },
    okBtnClick() {
      this.$emit('okBtnClick')
    },
    searchBtnClick() {
      this.$emit('searchBtnClick')
    }
  },
  watch: {
    value() {
      this.search = this.value
      if (this.search.length > 0)
        this.clearBtn = true
      else
        this.clearBtn = false
    }
  }
}
</script>

<style>
.formText {padding-top: 5px;}
.formText .icon {margin-left: 6px;}
.formText .icon img {width:24px; height: 24px;}
.formText .okBtn {font-size: 13px; line-height:18px; padding:5px 10px; border-radius: 4px; background-color: #B2B2B2; color:#FFFFFF; /*position: absolute; top:50%; transform: translateY(-50%); right:0;*/ margin-left: 6px;}
.formText.round .form-control {border-radius: 4px;padding: 14px; position: relative;}
.formText.round .end-0 {transform: translateX(-14px);}
.formText.line .form-control {padding: 13px 0; border-top: 0; border-left: 0; border-right: 0;}
.formText.inlabel .form-control {padding-top: 30px; padding-bottom: 10px;}
.formText.inlabel .end-0 {top: 30px;}
.formText.inlabel .form-label {position: absolute; top: 15px; left: 14px; z-index: 1;}
.formText.rounded-1 {margin: 8px auto; padding-top: 0;}
.formText.rounded-1 .form-control {background: transparent; border: 0; padding-right: 34px;}
.formText.rounded-1 .end-0 {right:12px !important;}
.formText .form-control {font-size: 16px;line-height: 22px;color: #000000;border-radius: 0;border-color: #e0e0e0;}
.formText .form-control::placeholder {color: #b2b2b2;}
.formText .form-control:focus {box-shadow: unset; border-color:#06B4EA; caret-color: #06B4EA;}
.formText .form-control.is-valid {background-image: unset; box-shadow: unset; border-color:#06B4EA; caret-color: #06B4EA;}
.formText .form-control.is-invalid {background-image: unset; box-shadow: unset; caret-color: #f13218; border-color:#f13218;}
.formText .form-control:disabled,
.formText .form-control:disabled::placeholder {background: unset;color: #e0e0e0;}
.formText .form-control[readonly] {background: #F4F4F4; border-color: #F4F4F4; color:#000000;}
.formText .form_error {margin-top: 8px;font-size: 12px;color: #f13218;line-height: 16px;padding-left: 21px;background: url('/img/form_error.png') no-repeat left top;background-size: 17px;}
.form-control[type="date"] {-webkit-appearance: none; -moz-appearance:none;background:url('/img/ico_date.png') no-repeat bottom 12px right 20px; background-size: 24px;}
.form-control[type="date"]::-ms-clear,
.form-control[type="date"]::-ms-reveal { display: none; width: 0; height: 0;}
.form-control[type="date"]::-webkit-search-decoration,
.form-control[type="date"]::-webkit-search-cancel-button,
.form-control[type="date"]::-webkit-search-results-button,
.form-control[type="date"]::-webkit-search-results-decoration { display: none;}
.form-control[type="date"]:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset; -webkit-text-fill-color: #000;}
.form-control[type="date"]:-webkit-autofill,
.form-control[type="date"]:-webkit-autofill:hover,
.form-control[type="date"]:-webkit-autofill:focus,
.form-control[type="date"]:-webkit-autofill:active { transition: background-color 5000s ease-in-out 0s;}
.form-control[type="date"]::-webkit-date-and-time-value{ text-align:left;}
.form-control[type="date"]::-webkit-calendar-picker-indicator {cursor: pointer; opacity: 1; display: block; background:transparent; background-size: 24px; min-width: 24px; height: 24px;}
.form-control[type='date']::before {content: attr(data-placeholder); color:#B2B2B2; background-color: #FFF; position: absolute; left: 0; padding-right: 50px;}
.form-control[type='date']:focus::before,
.form-control[type='date']:valid::before {display: none;}
.formText .form-control:disabled{
  color:#000000;
}
</style>
