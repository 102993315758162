import request from './axios';

const getPopular = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_popular_coach',
    method: 'post',
  });
};

const get = (last, name, order, voice, non_language, mental_care) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  if (name){
    formData.append('name', name);
  }

  if (order){
    formData.append('order', order);
  }

  if (voice){
    voice.forEach((value, index) => {
      formData.append(`voice[${index}]`, value);
    })
  }

  if (non_language){
    non_language.forEach((value, index) => {
      formData.append(`non_language[${index}]`, value);
    })
  }

  if (mental_care){
    mental_care.forEach((value, index) => {
      formData.append(`mental_care[${index}]`, value);
    })
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coach',
    method: 'post',
    data: formData,
  });
};

const getDetail = (co_id) => {
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coach_detail',
    method: 'post',
    data: formData,
  });
};

const setFollow = (co_id) => {
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coach_follow',
    method: 'post',
    data: formData,
  });
};

const getFollow = () => {
  //팔로우 리스트
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_follow',
    method: 'post',
  });
};

const deleteFollow = (co_id) => {
  //팔로우 삭제
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_follow',
    method: 'post',
    data: formData,
  });
};

const CoachService = {
  getPopular,
  get,
  getDetail,
  setFollow,
  getFollow,
  deleteFollow
};

export default CoachService;
