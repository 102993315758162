import request from './axios';

const get = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_cart',
    method: 'post',
    data: formData,
  });
};

const del = (od_id) => {
  const formData = new FormData();
  formData.append('od_id', od_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_cart',
    method: 'post',
    data: formData,
  });
};

const setCart = (
  co_id,
  date,
  hour,
  minute,
  type,
  method,
  staff,
  voice,
  non_language,
  mental_care
) => {
  const formData = new FormData();
  formData.append('co_id', co_id);
  formData.append('date', date);
  formData.append('hour', hour);
  formData.append('minute', minute);
  formData.append('type', type);
  formData.append('method', method);
  formData.append('staff', staff);

  if (voice){
    voice.forEach((value, index) => {
      formData.append(`voice[${index}]`, value);
    })
  }

  if (non_language){
    non_language.forEach((value, index) => {
      formData.append(`non_language[${index}]`, value);
    })
  }

  if (mental_care){
    mental_care.forEach((value, index) => {
      formData.append(`mental_care[${index}]`, value);
    })
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_cart',
    method: 'post',
    data: formData,
  });
};

const purchase = (od_id, memo, type) => {
  const formData = new FormData();
  formData.append('od_id', od_id);
  formData.append('type', type);

  if (memo){
    formData.append('memo', memo);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_purchase',
    method: 'post',
    data: formData,
  });
};

const getEnable = (
  co_id,
  date,
  hour,
  minute,
) => {
  const formData = new FormData();
  formData.append('co_id', co_id);
  formData.append('date', date);
  formData.append('hour', hour);
  formData.append('minute', minute);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_purchase_enable',
    method: 'post',
    data: formData,
  });
};

const getPurchase = (
  od_id,
) => {
  const formData = new FormData();
  formData.append('od_id', od_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_purchase',
    method: 'post',
    data: formData,
  });
};

const getPurchaseList = (
  last,
) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_purchase_list',
    method: 'post',
    data: formData,
  });
};

const setCancel = (
  od_id,
  reason
) => {
  const formData = new FormData();
  formData.append('od_id', od_id);
  formData.append('reason', reason);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_cancel',
    method: 'post',
    data: formData,
  });
};

const getExactList = (
  last,
  from,
  to
) => {
  const formData = new FormData();

  if (last){
    formData.append('last', last);
  }

  if (from){
    formData.append('from', from);
  }

  if (to){
    formData.append('to', to);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_exact_list',
    method: 'post',
    data: formData,
  });
};

const CartService = {
  get,
  del,
  purchase,
  setCart,
  getEnable,
  getPurchase,
  setCancel,
  getPurchaseList,
  getExactList
};

export default CartService;
