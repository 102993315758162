import request from './axios';

const doExistId = (id) => {
  const formData = new FormData();
  formData.append('id', id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/id_check',
    method: 'post',
    data: formData,
  });
};

const doExistNick = (nick) => {
  const formData = new FormData();
  formData.append('nick', nick);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/nick_check',
    method: 'post',
    data: formData,
  });
};

const getSido = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_sido',
    method: 'post'
  });
};

const getGugun = (sido) => {
  const formData = new FormData();
  formData.append('sido', sido);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_gugun',
    method: 'post',
    data: formData,
  });
};

const doJoin = (params) => {
  const formData = new FormData();
  for (const key in params) {
    formData.append(key, params[key]);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/join',
    method: 'post',
    data: formData,
  });
};

const doCoachJoin = (params) => {
  const formData = new FormData();
  for (const key in params) {
    if (Array.isArray(params[key])){
      params[key].forEach(function (item, index){
        formData.append(`${key}[${index}]`, item);
      })
    } else {
      formData.append(key, params[key]);
    }

  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/coach_join',
    method: 'post',
    data: formData,
  });
};

const JoinService = {
  doExistId,
  doExistNick,
  getSido,
  getGugun,
  doJoin,
  doCoachJoin
};

export default JoinService;
