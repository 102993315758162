import request from './axios';

const get = (co_id) => {
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_content',
    method: 'post',
    data: formData,
  });
};

const TermsService = {
  get,
};

export default TermsService;
