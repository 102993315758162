import MemberService from '@/api/MemberService'

//유저정보
export const getUserInfo = 'getUserInfo'
export const logout = 'logout'

export const userModule = {
  state: () => ({
    user: null,
    isLogin: false,
    isCoach: false
  }),
  actions: {
    getUserInfo: async (context) => {
      //유저정보
      if ((sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')) === null){
        return null
      }

      try {
        let eolevel = sessionStorage.getItem('eolevel') || localStorage.getItem('eolevel')

        context.state.user = eolevel > 2 ?
          await MemberService.getCoach()
          :
          await MemberService.get()

        context.state.isLogin = true
        context.state.isCoach = eolevel > 2

        return context.state.user
      } catch (e) {
        console.log(e.message)
      }
    },
    logout: async (context) => {
      //로그아웃
      await MemberService.setPushToken('')
      sessionStorage.clear()
      localStorage.clear()
      context.state.user = null
      context.state.isLogin = false
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    isLogin(state){
      return state.isLogin
    },
    isCoach(state){
      return state.isCoach
    }
  }
}