<template>
  <div
    class="chkBox"
  >

    <Check
      :chkType="3"
      :labelText="'전체'"
      :chkId="'t31'"
      :value="'전체'"
      v-model='isAllCheck'
    />

    <Check
      :chkType="3"
      :labelText="'CEO'"
      :chkId="'t32'"
      :value="'CEO'"
      v-model='mentalCare'
    />

    <Check
      :chkType="3"
      :labelText="'면접'"
      :chkId="'t33'"
      :value="'면접'"
      v-model='mentalCare'
    />

    <Check
      :chkType="3"
      :labelText="'발표'"
      :chkId="'t34'"
      :value="'발표'"
      v-model='mentalCare'
    />

    <Check
      :chkType="3"
      :labelText="'방송'"
      :chkId="'t35'"
      :value="'방송'"
      v-model='mentalCare'
    />

    <Check
      :chkType="3"
      :labelText="'고객응대'"
      :chkId="'t36'"
      :value="'고객응대'"
      v-model='mentalCare'
    />

  </div>
</template>

<script setup>
import Check from '@/components/Check.vue'
import {useStore} from 'vuex'
import {computed, onMounted, reactive, watch, ref, defineProps, defineEmits} from 'vue'

const props = defineProps({
  modelValue: {
    type: [String]
  }
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()

const isAllCheck = ref('')

const mentalCare = ref([])

const isAll = computed(() => mentalCare.value?.length === 5)

watch(props, (newValue) => {
  mentalCare.value = newValue.modelValue
})

watch(mentalCare, (newValue) => {
  if (isAll.value){
    isAllCheck.value = '전체'
  } else {
    isAllCheck.value = ''
  }

  emit('update:modelValue', newValue)
})

watch(isAllCheck, (newValue) => {
  if (newValue === '전체'){
    mentalCare.value = [
      'CEO', '면접', '발표',
      '방송', '고객응대'
    ]
  } else {
    if (isAll.value){
      mentalCare.value = []
    }
  }
})


</script>
