import request from './axios';

const get = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/info',
    method: 'post',
  });
};

const getCoach = () => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/coach_info',
    method: 'post',
  });
};

const leave = () => {
  //회원탈퇴
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/leave',
    method: 'post',
  });
};

const logout = () => {
  //로그아웃
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/logout',
    method: 'post',
  });
};

const setProfile = (profile) => {
  //프로필 이미지 업로드
  const formData = new FormData();
  formData.append('profile', profile);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_profile',
    method: 'post',
    data: formData,
  });
};

const setUserUpdate = (
  nick,
  pw,
  sex,
  birth,
  sido,
  gugun,
  job,
  etc
) => {
  //회원정보 수정
  const formData = new FormData();
  formData.append('nick', nick);

  if (pw !== ''){
    formData.append('pw', pw);
  }

  formData.append('sex', sex);
  formData.append('birth', birth);
  formData.append('sido', sido);
  formData.append('gugun', gugun);
  formData.append('job', job);
  formData.append('etc', etc);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_user_update',
    method: 'post',
    data: formData,
  });
};

const setCoachUpdate = (
  pw,
  profile,
  job,
  etc,
  intro,
  voice,
  non_language,
  mental_care,
  career,
  career_intro,
  imgs,
  bank,
  bank_number,
  holder
) => {
  //회원정보 수정
  const formData = new FormData();

  if (pw !== ''){
    formData.append('pw', pw);
  }

  formData.append('profile', profile);
  formData.append('job', job);
  formData.append('etc', etc);
  formData.append('intro', intro);

  if (voice){
    voice.forEach((value, index) => {
      formData.append(`voice[${index}]`, value);
    })
  }

  if (non_language){
    non_language.forEach((value, index) => {
      formData.append(`non_language[${index}]`, value);
    })
  }

  if (mental_care){
    mental_care.forEach((value, index) => {
      formData.append(`mental_care[${index}]`, value);
    })
  }

  formData.append('career', career);
  formData.append('career_intro', career_intro);

  if (imgs){
    imgs.forEach((value, index) => {
      formData.append(`imgs[${index}]`, value);
    })
  }

  formData.append('bank', bank);
  formData.append('bank_number', bank_number);
  formData.append('holder', holder);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coach_update',
    method: 'post',
    data: formData,
  });
};

const getUserDetail = (
  mb_id
) => {
  //회원 상세
  const formData = new FormData();
  formData.append('mb_id', mb_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_user_detail',
    method: 'post',
    data: formData,
  });
};

const setCoachAccountUpdate = (
  bank,
  bank_number,
  holder
) => {
  //회원정보 수정
  const formData = new FormData();

  formData.append('bank', bank);
  formData.append('bank_number', bank_number);
  formData.append('holder', holder);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coach_account_update',
    method: 'post',
    data: formData,
  });
};

const setPushToken = (
  token
) => {
  //회원정보 수정
  const formData = new FormData();

  formData.append('token', token);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/register_push_token',
    method: 'post',
    data: formData,
  });
};

const setPushSetting = (
  alarm1,
  alarm2,
  alarm3
) => {
  //회원정보 수정
  const formData = new FormData();

  formData.append('alarm1', `${alarm1}`);
  formData.append('alarm2', `${alarm2}`);
  formData.append('alarm3', `${alarm3}`);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/push_toggle',
    method: 'post',
    data: formData,
  });
};

const MemberService = {
  get,
  getCoach,
  leave,
  logout,
  setProfile,
  setUserUpdate,
  setCoachUpdate,
  getUserDetail,
  setCoachAccountUpdate,
  setPushToken,
  setPushSetting
};

export default MemberService;
