import request from './axios';

const getCalculate = (co_id, type, staff) => {
  const formData = new FormData();
  formData.append('co_id', co_id);
  formData.append('type', type);
  formData.append('staff', staff);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_calculate_price',
    method: 'post',
    data: formData,
  });
};

const getConsulting = (last, is_my = false, voice, non_language, mental_care, is_paging) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  formData.append('is_my', is_my);

  if (voice){
    voice.forEach((value, index) => {
      formData.append(`voice[${index}]`, value);
    })
  }

  if (non_language){
    non_language.forEach((value, index) => {
      formData.append(`non_language[${index}]`, value);
    })
  }

  if (mental_care){
    mental_care.forEach((value, index) => {
      formData.append(`mental_care[${index}]`, value);
    })
  }

  if (is_paging){
    formData.append('is_paging', is_paging);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coaching_consulting',
    method: 'post',
    data: formData,
  });
};

const getCoachConsulting = (co_id) => {
  const formData = new FormData();
  formData.append('co_id', co_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coach_coaching_consulting',
    method: 'post',
    data: formData,
  });
};

const getConsultingView = (cc_id) => {
  const formData = new FormData();
  formData.append('cc_id', cc_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coaching_consulting_view',
    method: 'post',
    data: formData,
  });
};

const getConsultingReply = (cc_id) => {
  const formData = new FormData();
  formData.append('cc_id', cc_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coaching_consulting_reply',
    method: 'post',
    data: formData,
  });
};

const setConsulting = (cc_id, subject, content, voice, non_language, mental_care, voice_file, etc_file) => {
  const formData = new FormData();

  if (cc_id){
    formData.append('cc_id', cc_id);
  }

  formData.append('subject', subject);
  formData.append('content', content);

  if (voice){
    voice.forEach((value, index) => {
      formData.append(`voice[${index}]`, value);
    })
  }

  if (non_language){
    non_language.forEach((value, index) => {
      formData.append(`non_language[${index}]`, value);
    })
  }

  if (mental_care){
    mental_care.forEach((value, index) => {
      formData.append(`mental_care[${index}]`, value);
    })
  }

  if (voice_file){
    formData.append('voice_file', voice_file);
  }

  if (etc_file){
    etc_file.forEach((value, index) => {
      formData.append(`etc_file[${index}]`, value);
    })
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coaching_consulting',
    method: 'post',
    data: formData,
  });
};

const deleteConsulting = (cc_id) => {
  const formData = new FormData();
  formData.append('cc_id', cc_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_coaching_consulting',
    method: 'post',
    data: formData,
  });
};

const setGood = (cc_id) => {
  const formData = new FormData();
  formData.append('cc_id', cc_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_good',
    method: 'post',
    data: formData,
  });
};

const setReport = (type, id, content) => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('id', id);
  formData.append('content', content);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_report',
    method: 'post',
    data: formData,
  });
};

const setConsultingApplicateComment = (id, content) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('content', content);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_consulting_applicate_comment',
    method: 'post',
    data: formData,
  });
};

const getConsultingApplicateComment = (id) => {
  const formData = new FormData();
  formData.append('id', id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_consulting_applicate_comment',
    method: 'post',
    data: formData,
  });
};

const delConsultingApplicateComment = (cac_id) => {
  const formData = new FormData();
  formData.append('cac_id', cac_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/det_consulting_applicate_comment',
    method: 'post',
    data: formData,
  });
};

const getConsultingApplicate = (last) => {
  const formData = new FormData();
  if (last){
    formData.append('last', last);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_consulting_applicate',
    method: 'post',
    data: formData,
  });
};

const getZoom = (last, is_ready) => {
  const formData = new FormData();

  if (last){
    formData.append('last', last);
  }

  if (is_ready){
    formData.append('is_ready', is_ready);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_zoom',
    method: 'post',
    data: formData,
  });
};

const getCoachingList = (is_ready, last, from, to) => {
  const formData = new FormData();
  formData.append('is_ready', is_ready);

  if (last){
    formData.append('last', last);
  }

  if (from){
    formData.append('from', from);
  }

  if (to){
    formData.append('to', to);
  }

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/get_coaching_list',
    method: 'post',
    data: formData,
  });
};

const setZoomLink = (od_id, link) => {
  const formData = new FormData();
  formData.append('od_id', od_id);
  formData.append('link', link);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_zoom_link',
    method: 'post',
    data: formData,
  });
};

const setCoachingUpdate = (od_id, date, hour, minute, link) => {
  const formData = new FormData();
  formData.append('od_id', od_id);
  formData.append('date', date);
  formData.append('hour', hour);
  formData.append('minute', minute);
  formData.append('link', link);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coaching_update',
    method: 'post',
    data: formData,
  });
};

const setCoachingConsultingReply = (cc_id, content) => {
  const formData = new FormData();
  formData.append('cc_id', cc_id);
  formData.append('content', content);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/set_coaching_consulting_reply',
    method: 'post',
    data: formData,
  });
};

const deleteCoachingConsultingReply = (cr_id) => {
  const formData = new FormData();
  formData.append('cr_id', cr_id);

  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/delete_coaching_consulting_reply',
    method: 'post',
    data: formData,
  });
};

const CoachingService = {
  getCalculate,
  getConsulting,
  getCoachConsulting,
  getConsultingView,
  getConsultingReply,
  setConsulting,
  deleteConsulting,
  setGood,
  setReport,
  setConsultingApplicateComment,
  getConsultingApplicateComment,
  delConsultingApplicateComment,
  getConsultingApplicate,
  getZoom,
  getCoachingList,
  setZoomLink,
  setCoachingUpdate,
  setCoachingConsultingReply,
  deleteCoachingConsultingReply
};

export default CoachingService;
