<template>
  <div
    class="Gnb d-flex align-items-center justify-content-between bg-white text-center"
  >

    <router-link
      :to="{name: 'Search'}"
      class="t11"
      data-testid="searchBtn"
    >

      <img
        src="/img/gnb1_off.png"
        class="off"
      >

      <img
        src="/img/gnb1_on.png"
        class="on"
      >

      코치검색
    </router-link>

    <router-link
      :to="{name: 'About'}"
      class="t11"
      @click.native.capture="doClick"
      data-testid="aboutBtn"
    >

      <img
        src="/img/gnb2_off.png"
        class="off"
      >

      <img
        src="/img/gnb2_on.png"
        class="on"
      >

      고객센터
    </router-link>

    <router-link
      :to="{name: 'Main'}"
      class="t11"
      data-testid="homeBtn"
    >

      <img
        src="/img/gnb3_off.png"
        class="off"
      >

      <img
        src="/img/gnb3_on.png"
        class="on"
      >

      홈
    </router-link>

    <router-link
      :to="{name: 'All'}"
      class="t11"
      @click.native.capture="doClick"
      data-testid="coachingBtn"
    >

      <img
        src="/img/gnb4_off.png"
        class="off"
      >

      <img
        src="/img/gnb4_on.png"
        class="on"
      >

      코칭상담
    </router-link>

    <router-link
      :to="{name: 'MyPage'}"
      class="t11"
      @click.native.capture="doClick"
      data-testid="myPageBtn"
    >

      <img
        src="/img/gnb5_off.png"
        class="off"
      >

      <img
        src="/img/gnb5_on.png"
        class="on"
      >

      마이페이지
    </router-link>

  </div>

</template>

<script setup>

//로그인 여부
import {computed} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'

const store = useStore()

const isLogin = computed(() => store.getters.isLogin)

const router = useRouter()

const doClick = (e) => {
  if (!isLogin.value){
    e.preventDefault()
    if (confirm('로그인 후 사용하실 수 있습니다.\n로그인 하시겠습니까?')){
      router.push({name: 'Login'})
    }
  }
}

</script>

<style>
.Gnb {position: fixed; left: 0; width: 100%; bottom: 0; height: 58px; border-radius: 20px 20px 0 0; padding:0 30px; z-index: 2; border-top: 1px solid #F1F6FC;}
.Gnb .t11 {color:#A4A4A4;}
.Gnb .t11.router-link-exact-active,
.Gnb .t11.router-link-active { font-weight: 500; color:#06B4EA;}
.Gnb .t11 img {width:24px; height: 24px; margin:0 auto 2px; display: block;}
.Gnb .t11 .on,
.Gnb .t11.router-link-exact-active .off,
.Gnb .t11.router-link-active .off {display: none;}
.Gnb .t11.router-link-exact-active .on,
.Gnb .t11.router-link-active .on {display: block;}
</style>