<template>
  <div class="chk">
    <input
      type="checkbox"
      :id="chkId"
      class="d-none"
      :value="value"
      :checked='isChecked'
      v-model="model"
      :data-testid="dataTestid"
    />

    <label
      :for="chkId"
      class="d-flex align-items-center"
      :class="labelClass"
    >
      <img src="/img/chk_off.png" class="w-100 off" />
      <img src="/img/chk_on.png" class="w-100 on" />
      {{ labelText }}
    </label>

  </div>
</template>

<script>
export default {
  name: 'Check',
  props: {
    modelValue: {
      type: Boolean
    },
    chkType: {
      type: Number
    },
    labelText: {
      type: String
    },
    chkId: {
      type: String
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    dataTestid: {
      type: String
    }
  },
  data() {
    return {
      labelClass: [],
      check: false
    }
  },
  mounted() {
    // TYPE
    if (this.chkType === 1) {
      this.labelClass = ['t16', 'fw-medium', 'base0']
    }
    if (this.chkType === 2) {
      this.labelClass = ['t14', 'fw-medium', 'base3']
    }
    if (this.chkType === 3) {
      this.labelClass = ['t13', 'fw-medium', 'base2']
    }
    if (this.chkType === 4) {
      this.labelClass = ['t13', 'base3']
    }
    if (this.chkType === 5) {
      this.labelClass = []
    }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.value
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue]
          if (value) {
            newValue.push(this.value)
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }
          this.$emit('update:modelValue', newValue)
        } else {
          this.$emit('update:modelValue', value ? this.value : '')
        }
      },
    },
  },
  methods: {

  }
}
</script>
<style>
.chk .w-100 {max-width: 18px;}
.chk .base0 .w-100 {margin-right: 8px;}
.chk .t14.base3 .w-100 {margin-right: 8px;}
.chk .base2 .w-100 {margin-right: 10px;}
.chk .t13.base3 .w-100 {margin-right: 6px;}
.chk .on {display: none;}
.chk .d-none:checked + .d-flex .off {display: none;}
.chk .d-none:checked + .d-flex .on {display: block;}
</style>
