<template>
  <q-file
    :bg-color="btnBg"
    :label="btnText"
    :class="btnClass"
    :outlined="btnOutline"
    :multiple='multiple'
    :accept='accept'
    :max-files='maxFiles'
    :append='append'
    :borderless='borderless'
    :style="cssVars"
    :input-style='inputStyle'
    :clearable='clearable'
    stack-label='true'
    v-model='file'
    ref='file'
  />
</template>

<script>
export default {
  name: 'Buttoncopy',
  props: {
    btnType: {
      type: Number
    },
    btnText: {
      type: String
    },
    btnSize: {
      type: String
    },
    btnOutline: {
      type: Boolean
    },
    btnBg: {
      type: String
    },
    multiple: {
      type: Boolean
    },
    accept: {
      type: String
    },
    maxFiles: {
      type: Number
    },
    append: {
      type: Boolean
    },
    borderless: {
      type: Boolean
    },
    labelColor: {
      type: String
    },
    inputStyle: {
      type: String
    },
    clearable: {
      type: Boolean
    },
  },
  data() {
    return {
      btnClass: [],
      file: null,
    }
  },
  watch: {
    file: function(newValue){
      let isFake = false
      if (Array.isArray(newValue)){
        newValue.forEach((value, index) => {
          if (newValue[index].size > 0){
            isFake = true;
          } else {
            this.$refs.file.removeAtIndex(index);
          }
        })
      } else {
        if (newValue.size === 0 || newValue.size === '0'){
          this.$refs.file.removeAtIndex(0);
          return
        }
      }

      if (isFake){
        this.$emit('update:modelValue', newValue.filter(value => value.size > 0))
        return
      }

      this.$emit('update:modelValue', newValue)

    }
  },
  computed: {
    cssVars () {
      return {
        '--button-label-color': this.$props?.labelColor ?? '#06b4ea'
      }
    }
  },
  mounted() {
    // TYPE
    if (this.btnType === 1) {
      this.btnClass = ['large']
    }
    if (this.btnType === 2) {
      this.btnClass = ['medium']
    }
    if (this.btnType === 3) {
      this.btnClass = ['small']
    }
    if (this.btnType === 4) {
      this.btnClass = ['xsmall']
    }
  }
}
</script>

<style lang="scss">
.row{
  --bs-gutter-y:0!important;
  --bs-gutter-x:0!important;
}
.q-field__label{
  text-align: center!important;
  color: var(--button-label-color) !important;
  font-weight: 400 !important;
}
.q-field--outlined .q-field__control::before{
  border-color: #06b4ea !important;
}
.q-field--outlined.q-field--highlighted .q-field__control::after{
  border-color: #06b4ea !important;
}
.q-field--float .q-field__label{
  transform: none!important;
}
.q-field--auto-height.q-field--labeled .q-field__native div{
  display: none;
}
.q-field.xsmall,
.q-field.xsmall *,
.q-field.xsmall .q-field__input{
  font-size: 12px!important;
}
.q-field.xsmall .q-field--auto-height .q-field__control, .q-field--auto-height .q-field__native{
  min-height: auto;
}
.q-field.xsmall .q-field__label{
  line-height: 2.5;
  top:0;
}
.q-field.xsmall{
  width: 80px!important;
  height: 30px!important;
  overflow: hidden!important;
}
</style>
