<template>
  <div
    class="Sect1 p-20"
  >
    <div
      class="position-relative"
    >

      <img
        src="/img/mark1.png"
        class="w-100 mark1"
        v-if="props.isBlank"
        @click="$emit('click')"
      />

      <Swiper
        :pagination="{
          el: '.swiper-pagination',
          type: 'fraction'
        }"
        :slidesPerView='1'
        :loopAdditionalSlides='0'
        :loop='true'
        class="rounded-2 overflow-hidden"
        data-testid="swiper"
      >

        <SwiperSlide
          v-for='(item, index) in banner'
          v-bind:key='index'
          @click='doLink(item)'
          :data-testid="`mainVisual${index + 1}`"
        >
          <div
            class='ratio'
            style="--bs-aspect-ratio: 56%;"
          >
            <img
              :src='item?.banner_img'
              class="img-fluid w-100 h-auto p-0 m-0"
              :onerror="'this.src=\'/img/default_img.jpg\';'"
            />
          </div>

        </SwiperSlide>

        <!-- no list -->
<!--        <SwiperSlide-->
<!--          v-if='banner.length === 0'-->
<!--        >-->
<!--          <div-->
<!--            class='ratio'-->
<!--            style="&#45;&#45;bs-aspect-ratio: 56%;"-->
<!--          >-->
<!--            <img-->
<!--              src='/img/default_img.jpg'-->
<!--              class="img-fluid w-100"-->
<!--            />-->
<!--          </div>-->

<!--        </SwiperSlide>-->

      </Swiper>

      <div class="swiper-pagination rounded-pill t12"></div>
    </div>

  </div>
</template>

<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue'
import SwiperCore, {Pagination, Navigation} from 'swiper'
SwiperCore.use([Pagination, Navigation])
import {ref, defineProps, onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import {getMainBanner} from '@/store'

const store = useStore()

const props = defineProps(['isBlank']);

//배너
const banner = ref([])

onMounted(async () => {
  banner.value = await store.dispatch(getMainBanner)
  const swiper = document.querySelector('.swiper').swiper;
  swiper.slideNext();
})

const doLink = (item) => {
  //링크 이동

  if (item?.links !== '' && item?.links !== 'http://' && item?.links !== 'https://'){
    item?.links?.openLink()
  }

}
</script>
