import {createStore} from 'vuex'
import BannerService from '@/api/BannerService'
import ReviewService from '@/api/ReviewService'
import VideoService from '@/api/VideoService'
import {mainModule} from '@/store/modules/mainModule'
import {userModule} from '@/store/modules/userModule'
import VuexPersistence from 'vuex-persist'

//메인 배너
export const getMainBanner = 'getMainBanner'
//파라메터
export const setParam = 'setParam'

export default createStore({
  modules: {
    userModule,
    mainModule
  },
  state: {
    banner: null,
    param: null,
  },
  mutations: {
    setParam: (state, payload) => {
      state.param = payload
    }
  },
  actions: {
    getMainBanner: async (context) => {
      //메인 배너
      if (context.state.banner !== null){
        return context.state.banner
      }

      try {
        const response = await BannerService.get()
        context.state.banner = response.list
        return context.state.banner
      } catch (e) {
        console.log(e.message)
      }
    },
  },
  getters: {
    getParam: (state) => {
      return state.param
    },
    getOs: (state) => {
      return (window?.reactNative || window?.webkit?.messageHandlers?.reactNative) ? 'app' : 'web'
    }
  },
  plugins: [new VuexPersistence().plugin]
})
