<template>
  <div
    class="Main" :class="{blank_bg: isBlank}"
  >
    <Nav
      :navType="'lg'"
      :logo="$route?.meta?.logo"
      :filter="$route?.meta?.filter"
      :cart="$route?.meta?.cart"
      :alarm="$route?.meta?.alarm"
    />

    <!-- 메인 비쥬얼 -->
    <MainVisual
      :isBlank='isBlank'
      @click='closeBg'
    />

    <slot />

    <!-- 푸터 -->
    <Footer />

    <Gnb
      v-if='!isCoach'
    />

    <div
      class="min-vh-100 position-fixed start-0 w-100 top-0 bg-black opacity-6"
      v-if="isBlank"
      @click="closeBg"
    />

  </div>
</template>

<script setup>
import {Nav, MainVisual, Footer, Gnb} from '@/components'
import {useRoute} from 'vue-router'
import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {setBlank} from '@/store/modules/mainModule'

const route = useRoute()

const store = useStore()

//로그인 여부
const isLogin = computed(() => store.getters.isLogin)

//코치 여부
const isCoach = computed(() => store.getters.isCoach)

//가이드 표시 여부
const isBlank = computed(() => store.getters.isBlank)

onMounted(() => {
  store.commit(setBlank, !isLogin.value)
})

const closeBg = () => {
  //가이드 닫기
  store.commit(setBlank, false)
}

</script>

<style scoped>
.blank_bg {height: 100vh; overflow: hidden;}
</style>