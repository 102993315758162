const routes = {
  path: '/coach',
  name: 'CoachMain',
  meta: {
    layout: 'SubLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/Main.vue'),
  children: [
    {
      path: 'video',
      name: 'Video',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/Video.vue'),
      props: true,
      meta: {
        titleText: '보이스 코칭 영상'
      },
    },
    {
      path: 'search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/Search.vue'),
      props: true,
      meta: {
        layout: 'GnbLayout',
      }
    },
    {
      path: 'profile',
      name: 'Profile',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/Profile.vue'),
      props: true
    },
    {
      path: 'coaching',
      name: 'Coaching',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/Coaching.vue'),
      props: true,
      meta: {
        layout: 'PopupLayout',
      },
    },
    {
      path: 'coachinglist',
      name: 'CoachingList',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/CoachingList.vue'),
      props: true,
      meta: {
        titleText: '코칭신청내역'
      },
    },
    {
      path: 'zoomlist',
      name: 'ZoomList',
      component: () => import(/* webpackChunkName: "coach" */ '@/views/coach/ZoomList.vue'),
      props: true,
      meta: {
        titleText: 'ZOOM코칭 내역'
      },
    },
  ]
}

export default routes