const routes = {
  path: '/case',
  name: 'CaseMain',
  meta: {
    layout: 'CoachingLayout',
    back: true,
    txtType: 'left',
    titleText: ''
  },
  props: true,
  component: () => import(/* webpackChunkName: "case" */ '@/views/case/Main.vue'),
  children: [
    {
      path: 'all',
      name: 'All',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/All.vue'),
      meta: {
        dep: 1,
        titleText: '코칭상담'
      },
      props: true,
    },
    {
      path: 'view/:no?',
      name: 'View',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/View.vue'),
      props: true,
      meta: {
        titleText: '코칭상담'
      },
    },
    {
      path: 'mycase',
      name: 'MyCase',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/MyCase.vue'),
      meta: {
        dep: 2,
        titleText: '코칭상담'
      },
      props: true
    },
    {
      path: 'write/:no?',
      name: 'Write',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/Write.vue'),
      meta: {
        dep: 2,
        titleText: '코칭상담'
      },
      props: true
    },
    {
      path: 'ask',
      name: 'Ask',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/Ask.vue'),
      meta: {
        dep: 3,
        titleText: '코칭상담'
      },
      props: true
    },
    {
      path: 'detail',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "case" */ '@/views/case/Detail.vue'),
      meta: {
        dep: 3,
        titleText: '코칭상담'
      },
      props: true
    },
  ]
}

export default routes